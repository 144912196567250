const initState = {
    open: false
}
const START_RANDOM_EVENT = 'START_RANDOM_EVENT'
const STOP_RANDOM_EVENT = 'STOP_RANDOM_EVENT'
const randomEventInterval = 4000

let interval, randomEventTriggerFunction, randomEventStopFunction

export function switchRandomEvent(params) {
    return (dispatch) => {
        params? dispatch(startRandomEvent()): dispatch(stopRandomEvent())
    }
}

export function setRandomEventTrigger(func, stopFunc) {
    randomEventTriggerFunction = func
    randomEventStopFunction = stopFunc
    return (dispatch) => {
        dispatch(stopRandomEvent())
    }
}

function startRandomEvent() {
    return {
        type: START_RANDOM_EVENT
    }
}

function stopRandomEvent() {
    return {
        type: STOP_RANDOM_EVENT
    }
}

function startTriggerRandomEvent() {
    randomEventTriggerFunction()
    interval = setInterval(randomEventTriggerFunction, randomEventInterval)
}

function stopTriggerRandomEvent() {
    clearInterval(interval)
    interval = null
    randomEventStopFunction()
}

export default function randomEventReducer(state = initState, action) {
    switch (action.type) {
        case START_RANDOM_EVENT:
            startTriggerRandomEvent()
            return {
                ...state,
                open: true
            }
        case STOP_RANDOM_EVENT:
            stopTriggerRandomEvent()
            return {
                ...state,
                open: false
            }
        default:
            return state
    }
}
