/*eslint-disable*/
import axios from '../service'
const url = 'xbizapi/260/statistics'

let teachersService = {}

const apis = [
    {
        name: 'get',
        operationType: 'GET_TEA_INFO_PIE_CHART',
    },
    {
        name: 'getSingleAxisData',
        operationType: 'GET_ACTIVITY_INFO_CHART',
    },


]

apis.map((api) => {
    teachersService[api.name] = (data) => {
        return axios(url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default teachersService
