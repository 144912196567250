import { handleResultCode } from '../../service/service'
import loginService from '../../service/services/login'
import {push} from 'react-router-redux'
const START_LOGIN = 'START_LOGIN'
const FINISH_LOGIN = 'FINISH_LOGIN'
const START_GET_PROFILE = 'START_GET_PROFILE'
const FINISH_GET_PROFILE = 'FINISH_GET_PROFILE'

const initState = {
    userProfileIsFetching: false,
    isFetching: false,
    userProfile: null,
    functions: []
}

function startLogin() {
    window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
    return {
        type: START_LOGIN
    }
}

function finishLogin() {
    return {
        type: FINISH_LOGIN,
    }
}

export function login(params) {
    return (dispatch) => {
        dispatch(startLogin())
        loginService.login(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    window.localStorage.setItem(process.env.REACT_APP_LOCAL_AUTH, res.data.token)
                    dispatch(finishLogin())
                    dispatch(getUserProfile())
                    dispatch(push('/index/'))
                }
            })
            .catch((e) => {
                console.log(e)
                return
            })
    }
}

function startGettingUserProfile() {
    return {
        type: START_GET_PROFILE
    }
}

function finishGettingUserProfile(accessInfos, userDetailInfo, functions) {
    return {
        type: FINISH_GET_PROFILE,
        userProfile: {
            accessInfos,
            userDetailInfo
        },
        functions
    }
}

export function getUserProfile() {
    if (window.localStorage.getItem(process.env.REACT_APP_LOCAL_AUTH)) {
        return (dispatch) => {
            dispatch(startGettingUserProfile())
            loginService.getUserProfile()
                .then((response)=>{
                    return handleResultCode(response)
                })
                .then((res) => {
                    if(res.success) {
                        dispatch(finishGettingUserProfile(res.data.accessInfos, res.data.userDetailInfo, res.data.functions))
                    }
                })
        }
    } else {
        return (dispatch) => {
            dispatch(push('/login'))
        }
    }
}



export default function loginReducer(state = initState, action) {
    switch (action.type) {
        case START_LOGIN:
            return {
                ...state,
                isFetching: true
            }
        case FINISH_LOGIN:
            return {
                ...state,
                isFetching: false
            }
        case START_GET_PROFILE:
            return {
                ...state,
                userProfileIsFetching: true
            }
        case FINISH_GET_PROFILE:
            return {
                ...state,
                userProfileIsFetching: false,
                userProfile: action.userProfile,
                functions: action.functions
            }
        default:
            return state
    }
}
