/*eslint-disable*/
import axios from '../service'



let teachListService = {}

const apis = [
    {
        name: 'getTableList',
        operationType: 'GET_TEA_INFO_LIST',
        url: '/xbizapi/260/statistics'
    }
]

apis.map((api) => {
    teachListService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default teachListService
