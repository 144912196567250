import * as React from 'react'
import * as Loadable from 'react-loadable'
import {Redirect, Route, Switch } from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import {createHashHistory} from 'history'
import loading from '../components/Loading/loading'
import 'antd/dist/antd.less'
import '../styles/global.less'
function hasLogin() {
    return !!window.localStorage.getItem(process.env.REACT_APP_LOCAL_AUTH)
    // return true
}

const RooterMap = () => {
    return (<ConnectedRouter history={createHashHistory()}>
        <Switch>
            <Redirect from="/" to={hasLogin() ? '/index' : '/login'} exact={true}/>
            <Route path="/login" exact={true} component={Loadable({loader: ()=>import('../pages/login/index'), loading})} />
            <Route path="/index" component={Loadable({loader: ()=>import('../pages/index/index'), loading})} />
        </Switch>
    </ConnectedRouter>)
}

export default RooterMap
