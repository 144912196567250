import {handleResultCode} from '../../service/service'
import eventListService from '../../service/services/eventList'

const START_GET_TABLE_LIST = 'START_GET_TABLE_LIST'
const DID_GET_TABLE_LIST = 'DID_GET_TABLE_LIST'
const FAIL_GET_TABLE_LIST = 'FAIL_GET_TABLE_LIST'

const initState = {
    isFetchTableList: false,
    totalItemCount: 0,
    rows: [],
    pageSize: 0,
}


// 获取table 数据
export function getTableList(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetTableList())
        eventListService.getTableList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success && res.data.pageInfo) {
                    let {pageInfo} = res.data
                    dispatch(didGetTableList(pageInfo.list || [], pageInfo.totalSize))
                } else {
                    dispatch(failGetTableList())
                    failCb()
                }
            })
            .catch((e) => {
                dispatch(failGetTableList())
                failCb()
            })
    }
}

function startGetTableList() {
    return {
        type: START_GET_TABLE_LIST
    }
}

function didGetTableList(rows, totalItemCount) {
    return {
        type: DID_GET_TABLE_LIST,
        totalItemCount,
        rows,
    }
}

function failGetTableList() {
    return {
        type: FAIL_GET_TABLE_LIST
    }
}

export default function eventListReducer(state = initState, action) {
    switch (action.type) {
        case START_GET_TABLE_LIST:
            return {
                ...state,
                isFetchTableList: true
            }
        case DID_GET_TABLE_LIST:
            return {
                ...state,
                isFetchTableList: false,
                rows: action.rows,
                totalItemCount: action.totalItemCount,
            }
        case FAIL_GET_TABLE_LIST:
            return {
                ...state,
                isFetchTableList: false,
                rows: []
            }
        default:
            return state
    }
}
