import reduxLogger from 'redux-logger'
import reduxThunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducer'
import { routerMiddleware } from 'connected-react-router'
import history from '../router/history'
const historyMiddleware = routerMiddleware(history)

export default function configureStore() {
    if (process.env.REACT_APP_ENV_NAME === 'dev') {
        return createStore(
            rootReducer(history),
            applyMiddleware(reduxThunk, reduxLogger, historyMiddleware)
        )
    } else {
        return createStore(
            rootReducer(history),
            applyMiddleware(reduxThunk, historyMiddleware)
        )
    }
}