/*eslint-disable*/
import axios from '../service'
const url = 'xbizapi/260/user_auth'

let loginService = {}

const apis = [
    {
        name: 'login',
        operationType: 'GEN_TOKEN',
    },
    {
        name: 'getUserProfile',
        operationType: 'GET_ACCESS_INFO'
    }

]

apis.map((api) => {
    loginService[api.name] = (data) => {
        return axios(url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default loginService