import { handleResultCode } from '../../service/service'
import teacherService from '../../service/services/teachers'
const initState = {
    loading: false,
    loadSingleAxising: false,
    teachers: {
        jszc: [],
        ggjs: [],
        jsjl: [],
        jsxl: []
    },
    singleAxisData: [],
    xData: []
}
const START_GET_TEACHERS = 'START_GET_TEACHERS'
const FINISH_GET_TEACHERS = 'FINISH_GET_TEACHERS'

const START_GET_SINGLE_AXIS_DATA = 'START_GET_SINGLE_AXIS_DATA'
const FINISH_GET_SINGLE_AXIS_DATA = 'FINISH_GET_SINGLE_AXIS_DATA'
const FAIL_GET_SINGLE_AXIS_DATA = 'FAIL_GET_SINGLE_AXIS_DATA'

function startGetTeachers() {
    return {
        type: START_GET_TEACHERS
    }
}

function finishGetTeachers(teachers) {
    return {
        teachers,
        type: FINISH_GET_TEACHERS,
    }
}


// 获取散点图数据
export function getSingleAxisData(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetSingleAxisData())
        teacherService.getSingleAxisData(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {jyjx, jspx, hjqk, djqk, zygz, x} = res.data
                    const singleAxisData = [
                        {
                            type: 'edu_teach',
                            data: jyjx,
                        },{
                            type: 'teacher_train',
                            data: jspx,
                        },{
                            type: 'prize_situation',
                            data: hjqk,
                        },{
                            type: 'teach_situation',
                            data: djqk,
                        },{
                            type: 'important_work',
                            data: zygz,
                        },
                    ]
                    dispatch(finishGetSingleAxisData(singleAxisData, x))
                } else {
                    dispatch(failGetSingleAxisData())
                    failCb()
                }
            })
            .catch((e) => {
                dispatch(failGetSingleAxisData())
                failCb()
            })
    }
}

function startGetSingleAxisData() {
    return {
        type: START_GET_SINGLE_AXIS_DATA
    }
}

function finishGetSingleAxisData(singleAxisData, xData) {
    return {
        type: FINISH_GET_SINGLE_AXIS_DATA,
        singleAxisData,
        xData
    }
}

function failGetSingleAxisData() {
    return {
        type: FAIL_GET_SINGLE_AXIS_DATA
    }
}

// 修改singleAxis数据
export function changeSingleAxisData(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startChangeSingleAxisData())
        dispatch(finishChangeSingleAxisData(params))
        successCb()
    }
}

function startChangeSingleAxisData() {
    return {
        type: 'startChangeSingleAxisData'
    }
}
function finishChangeSingleAxisData(params) {
    return {
        type: 'finishChangeSingleAxisData',
        params
    }
}





export function getTeachers() {
    return (dispatch) => {
        dispatch(startGetTeachers())
        teacherService.get()
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {teaTitle, backBoneTea, teaWorkYears, teaEducation} = res.data
                    dispatch(finishGetTeachers({
                        teaTitle,
                        backBoneTea,
                        teaWorkYears,
                        teaEducation
                    }))
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function teacherReducer(state = initState, action) {
    switch (action.type) {
        case START_GET_TEACHERS:
            return {
                ...state,
                loading: true
            }
        case FINISH_GET_TEACHERS:
            return {
                ...state,
                loading: false,
                teachers: action.teachers
            }


        case START_GET_SINGLE_AXIS_DATA:
            return {
                ...state,
                loadSingleAxising: true
            }
        case FINISH_GET_SINGLE_AXIS_DATA:
            return {
                ...state,
                loadSingleAxising: false,
                singleAxisData: action.singleAxisData,
                xData: action.xData
            }
        case FAIL_GET_SINGLE_AXIS_DATA:
            return {
                ...state,
                loadSingleAxising: false,
                singleAxisData: [],
                xData: []
            }
        default:
            return state
    }
}
