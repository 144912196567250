import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loginReducer from './reducers/login'
import randomEventReducer from './reducers/randomEvent'
import teacherReducer from './reducers/teachers'
import teachListReducer from './reducers/teachListReducer'
import eventListReducer from './reducers/eventListReducer'
export default (history) => combineReducers({
    router: connectRouter(history),
    loginReducer,
    randomEventReducer,
    teacherReducer,
    teachListReducer,
    eventListReducer
})
