/*eslint-disable*/
import axios from '../service'



let eventListService = {}

const apis = [
    {
        name: 'getTableList',
        operationType: 'GET_ACTIVITY_INFO_LIST',
        url: '/xbizapi/260/statistics'
    }
]

apis.map((api) => {
    eventListService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default eventListService
